//数据类的静态文件谢在这里，目前涉及到的所有字典的key
const parmsArray = [
    'role_permission_type',
    'dict_type',
    'language_type',
    'param_type',
    'enable_status',
    'send_msg_status',
    'send_msg_scenes',
    'msg_sender_type',
    'log_type',
    'status_type',
    'whitelist_type',
    'notice_style',
    'chain_list_type',
    'has_token',
    'protocol_type',
    'coin_type',
    'has_tags_status',
    'isDeposit_or_isWithdraw'

]
const parmsObject = {
    'role_permission_type':['getRolePermissionType','setRolePermissionType'],
    'dict_type':['getDictType','setDictType'],
    'language_type':['getLanguageType','setLanguageType'],
    'param_type':['getParamType','setParamType'],
    'enable_status':['getEnableStatus','setEnableStatus'],
    'send_msg_status':['getSendMsgStatus','setSendMsgStatus'],
    'send_msg_scenes':['getSendMsgScenes','setSendMsgScenes'],
    'msg_sender_type':['getMsgSenderType','setMsgSenderType'],
    'log_type':['getLogType','setLogType'],
    'status_type':['getStatusType','setStatusType'],
    'whitelist_type':['getWhitelistType','setWhitelistType'],
    'notice_style':['getNoticeStyle','setNoticeStyle'],
    'chain_list_type':['getChainListType','setChainListType'],
    'has_token':['getHasToken','setHasToken'],
    'protocol_type':['getProtocolType','setProtocolType'],
    'coin_type':['getCoinType','setCoinType'],
    'has_tags_status':['getHasTags','setHasTags'],
    'isDeposit_or_isWithdraw':['getIsDepositOrIsWithdraw','setIsDepositOrIsWithdraw'],
}
export {
    parmsArray,parmsObject
}