import { defineStore } from "pinia";

export const parmStore = defineStore('parmStore', {
    state: () => {
        return {
            rolePermissionType: [],//角色管理的数据表权限
            dictType: [],//字典管理的类型
            languageType: [],//语言国际化
            paramType: [],//参数类别
            enableStatus: [],//启用状态
            sendMsgStatus: [],//发送消息状态
            sendMsgScenes: [],//发送场景类型
            msgSenderType: [],//信息发送渠道类型
            logType: [],//日志类型
            statusType: [],//租户状态,0,9
            whitelistType: [],//白名单管理,1,2
            noticeStyle: [],//通知设置，0,1
            chainListType: [],//虚拟机类型
            hasToken: [],//是否有token
            protocolType: [],//协议管理的适用场景
            coinType: [],//币种类型
            hasTags: [],//币种是否有便签
            isDepositOrIsWithdraw: [],//币的开关关于充值和提现
        }
    },
    getters: {
        getRolePermissionType(){
            return this.rolePermissionType
        },
        getDictType(){
            return this.dictType
        },
        getLanguageType(){
            return this.languageType
        },
        getParamType(){
            return this.paramType
        },
        getEnableStatus(){
            return this.enableStatus
        },
        getSendMsgStatus(){
            return this.sendMsgStatus
        },
        getSendMsgScenes(){
            return this.sendMsgScenes
        },
        getMsgSenderType(){
            return this.msgSenderType
        },
        getLogType(){
            return this.logType
        },
        getStatusType(){
            return this.statusType
        },
        getWhitelistType(){
            return this.whitelistType
        },
        getNoticeStyle(){
            return this.noticeStyle
        },
        getChainListType(){
            return this.chainListType
        },
        getHasToken(){
            return this.hasToken
        },
        getProtocolType(){
            return this.protocolType
        },
        getCoinType(){
            return this.coinType
        },
        getHasTags(){
            return this.hasTags
        },
        getIsDepositOrIsWithdraw(){
            return this.isDepositOrIsWithdraw
        }
    },
    actions: {
        setRolePermissionType(rolePermissionType){
            this.rolePermissionType = rolePermissionType;
        },
        setDictType(dictType){
            this.dictType = dictType;
        },
        setLanguageType(languageType){
            this.languageType = languageType;
        },
        setParamType(paramType){
            this.paramType = paramType;
        },
        setEnableStatus(enableStatus){
            this.enableStatus = enableStatus;
        },
        setSendMsgStatus(sendMsgStatus){
            this.sendMsgStatus = sendMsgStatus;
        },
        setSendMsgScenes(sendMsgScenes){
            this.sendMsgScenes = sendMsgScenes;
        },
        setMsgSenderType(msgSenderType){
            this.msgSenderType = msgSenderType;
        },
        setLogType(logType){
            this.logType = logType;
        },
        setStatusType(statusType){
            this.statusType = statusType;
        },
        setWhitelistType(whitelistType){
            this.whitelistType = whitelistType;
        },
        setNoticeStyle(noticeStyle){
            this.noticeStyle = noticeStyle;
        },
        setChainListType(chainListType){
            this.chainListType = chainListType;
        },
        setHasToken(hasToken){
            this.hasToken = hasToken;
        },
        setProtocolType(protocolType){
            this.protocolType = protocolType;
        },
        setCoinType(coinType){
            this.coinType = coinType;
        },
        setHasTags(hasTags){
            this.hasTags = hasTags;
        },
        setIsDepositOrIsWithdraw(isDepositOrIsWithdraw){
            this.isDepositOrIsWithdraw = isDepositOrIsWithdraw;
        }
    }
})