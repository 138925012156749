import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const object = [{
    type: 'select',
    name: t('currencySetNft.name1'),
    prop: 'tenantId',
    isPostApi: 'shopList',
    postURl: '',
    postData: [],
    isPlatform: 1,
    props:{
        value:'id',
        label:'name'
    }
},{
    type: 'select',
    name: t('currencySetNft.name2'),
    prop: 'appId',
    isPostApi: 'getUserappinfoList',
    postURl: '',
    postData: [],
    props:{
        value:'appId',
        label:'appName'
    }
},{
    type: 'select',
    name: t('currencySetNft.text6'),
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    }
},{
    type: 'select',
    name: t('currencySetNft.text7'),
    prop: 'protocol',
    isPostApi: 'getAgreementList',
    postURl: '',
    postData: [],
    props:{
        value:'protocol',
        label:'protocol'
    }
},{
    type: 'input',
    name: 'Token ID',
    prop: 'tokenId',
},{
    type: 'input',
    name: t('nfts.text13'),
    prop: 'collectionName',
},{
    type: 'input',
    name: t('withdrawNft.name11'),
    prop: 'itemName',
},{
    type: 'input',
    name: t('currencySetNft.name4'),
    prop: 'walletName',
},{
    type: 'input',
    name: t('withdrawNft.name13'),
    prop: 'fromAddress',
},{
    type: 'input',
    name: t('withdrawNft.name14'),
    prop: 'applyUser',
},{
    type: 'input',
    name: t('withdrawNft.name15'),
    prop: 'auditUser',
},{
    type: 'datetime',
    name: t('nfts.text14'),
    prop: 'applyTime',
},{
    type: 'select',
    name: t('currencySetNft.name9'),
    prop: 'status',
    postData: [{ label: t('withdrawNft.name18_1'), value: 0 },{label: t('withdrawNft.name18_2'),value:1},{label: t('withdrawNft.name18_3'),value:2}],
}]
export const collection_ = [
    {
        type: 'select',
        name: t('currencySetNft.text6'),
        prop: 'chain',
        isPostApi: 'getCoinList',
        postURl: '',
        postData: [],
        props:{
            value:'chain',
            label:'chain'
        },
    },{
        type: 'select',
        name: t('currencySetNft.text7'),
        prop: 'protocol',
        isPostApi: 'getAgreementList',
        postURl: '',
        postData: [],
        props:{
            value:'protocol',
            label:'protocol'
        }
    },{
        type: 'input',
        name: t('nfts.text13'),
        prop: 'collectionName',
    },{
        type: 'input',
        name: 'Token ID',
        prop: 'tokenId',
    },{
        type: 'input',
        name: '合约地址',
        prop: 'contractAddress',
    },{
        type: 'input',
        name: t('withdrawNft.name11'),
        prop: 'itemName',
    },
]
export const addr_nft_ = [{
    type: 'select',
    name: t('currencySetNft.text6'),
    prop: 'chain',
    isPostApi: 'getCoinList',
    postURl: '',
    postData: [],
    props:{
        value:'chain',
        label:'chain'
    },
},{
        type: 'input',
        name: t('otherWallatNft.name1'),
        prop: 'walletName',
    },{
        type: 'input',
        name: t('otherWallatNft.text6'),
        prop: 'walletAddress',
    }
]
