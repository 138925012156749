import Api_ from '@/api/index'
import { parmStore } from '@/store/parms.js'
import { parmsObject,parmsArray } from '@/config/data'

export const getDataStyle = (styleStr,bindFun)=>{
    if( parmStore()[parmsObject[styleStr][0]] && parmStore()[parmsObject[styleStr][0]].length > 0){//当前store拥有这个数据，就不进行二次数据的提取
        bindFun(parmStore()[parmsObject[styleStr][0]])
    }else{
        Api_.parmsType(styleStr).then(res=>{
            if(res.code === 0){
                bindFun(res.data)
                if(res.data.length === 0){
                    parmStore()[parmsObject[styleStr][1]]([''])
                }else{
                    parmStore()[parmsObject[styleStr][1]](res.data)
                }
            }
        })
    }
}
export const getArrayItem = (array_,value_)=>{//获得数组中的key对应的value\
    for(let item of array_){
        if(item.value == value_){
            return item.label
        }
    }
}
export const isHaveParm = (index,balk)=>{//判断当前字典是否有了
    if(parmStore()[parmsObject[parmsArray[index]][0]].length > 0){
        balk(parmStore()[parmsObject[parmsArray[index]][0]])
    }else{
        getDataStyle(parmsArray[index],balk)
    }
}
export const getDataAboutText = (index,items)=>{//获得字典对应的text
    return getArrayItem(parmStore()[parmsObject[parmsArray[index]][0]],items)
}
export const getDataAboutTextArray = (index)=>{//获得字典对应的text
    return parmStore()[parmsObject[parmsArray[index]][0]]
}