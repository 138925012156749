<template>
    <div class="pageCom">  
        <el-pagination
            v-model:current-page="obj_value.currentPage"
            v-model:page-size="obj_value.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :small="false"
            :disabled="false"
            :background="false"
            layout="sizes, prev, pager, next"
            :total="obj_value.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
</template>
<script setup>
    import { defineEmits,defineProps,ref,watchEffect } from 'vue'
    const emit = defineEmits(['sizeChange','currentChange'])
    const props = defineProps({
        page: Object
    })
    const obj_value = ref(null)
    const handleSizeChange = (e)=>{
        emit('sizeChange',e)
    }
    const handleCurrentChange = (e)=>{
        emit('currentChange',e)
    }

    watchEffect(()=>{
        obj_value.value = props.page;
    })

</script>
<style lang="scss" scoped>
    .pageCom{
        display: flex;
        justify-content: flex-end;
    }
</style>