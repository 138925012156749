<template>
    <div class="timeCheck">
        <el-form :model="form" label-width="auto" :inline='true' ref='fromView'>
            <template v-for='item in obj_value' :key = 'item.prop'>
                <el-form-item :label="item.name" :prop="item.prop"  :label-width="item.laberWidth" v-if='item.show'>
                    <template v-if='item.type === "input" && item.show'>
                        <el-input v-model="form[item.prop]" :placeholder="$t('searchText.text1')"/>
                    </template>
                    <template v-else-if='item.type === "select"'>
                        <el-select v-model="form[item.prop]" :placeholder="$t('searchText.text2')" :multiple='item.isTwo' filterable 
                            :teleported='false' :disabled='item.isRelation && !form[item.isRelation]' @change="getData_(item)">
                            <el-option v-for="items in item.postData" :key="items[item.props.value]"
                            :label="items[item.props.label]"
                            :value="items[item.props.value]" />
                        </el-select>
                    </template>
                    <template v-else-if='item.type === "datetime" && item.show'>
                        <el-date-picker v-model="form[item.prop]" type="datetimerange"
                            :range-separator="$t('searchText.text3')" :default-time="[new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59),]"
                            :start-placeholder="$t('searchText.text4')"
                            :end-placeholder="$t('searchText.text5')" />
                    </template>
                    <template v-else-if='item.type === "cascader" && item.show'>
                        <el-cascader v-model="form[item.prop]" :options="item.postData" />
                    </template>
                </el-form-item>
            </template>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">{{$t('button.search')}}</el-button>
                <el-button @click="removeClick">{{$t('button.remove')}}</el-button>
            </el-form-item>
        </el-form>    
    </div>
</template>
<script>
// export default {
//     name: 'fromSearch',
//     directives: {
//         lazyData: {
//             updated(el){
//                 let SELECT_DOM = el.querySelector(
//                     ".el-select-dropdown__wrap"
//                 );
//                 SELECT_DOM.addEventListener("scroll", function () {
//                     let condition =
//                         this.scrollHeight - this.scrollTop <= this.clientHeight;
//                     if (condition) {
//                         console.log(binding)
//                         // binding.value();
//                     }
//                 })

//             }
//         }
//     },
// }
</script>
<script setup>
    import { defineProps,ref,watchEffect,defineEmits,reactive,defineExpose } from 'vue'
    import { userStore } from '@/store/user'
    import Api_ from '@/api/index'
    import { getDataStyle  } from '@/utils/dataBind/parmsView'

    const emit = defineEmits(['searchFun','changeParm'])
    const props = defineProps({
        object_: Object
    })
    const obj_value = ref({})
    const form = ref({})
    const fromView = ref(null)
    const page_ = reactive({
        current: 1,
        size: 100000,
    })
    // const isWatchEffect = ref(false)

    const getData_ = (item_)=>{
        if(item_.isFartherFun){
            for(let item of obj_value.value){
                if(item.prop === item_.isFartherFun){
                    let obj_ = JSON.parse(JSON.stringify(page_));
                    obj_[item_.prop] = form.value[item_.prop]
                    Api_[item.isPostApi](obj_).then(res=>{
                        if(res.code === 0){
                            if(item.dicFormatter){
                                item.postData = item.dicFormatter(res.data)
                            }else{
                                item.postData = res.data.records || res.data
                            }
                        }
                    })
                    return 0
                }
            }
        }
    }
    const removeClick = ()=>{
        fromView.value.resetFields()
        emit('changeParm',{},1)
        emit('searchFun',{})
    }
    const onSubmit = ()=>{
        emit('searchFun',form.value)
        emit('changeParm',form.value)
    }
    const isPlatformW = (item)=>{//判断是否是否是平台字段，
        if(item.isPlatform){
            if(userStore().getIsPlatform){//当前用户是平台，显示下拉框
                item.show = true
            }else{//影长
                let obj = {tenantId: userStore().getUserInfo[0].tenantId}
                form.value = obj
                item.show = false
            }
        }else{
            item.show = true
        }
    }
    const getPostData = (item)=>{//判断是否需要进行数据请求来获得数据
        if(item.isPostApi && item.show && !item.isRelation){
            Api_[item.isPostApi](page_).then(res=>{
                if(res.code === 0){
                    if(item.dicFormatter){
                        item.postData = item.dicFormatter(res.data)
                    }else{
                        let postData = res.data.records || res.data
                        item.postData = postData
                    }
                }
            })
        }else if(item.isParms){//通过字典来获取
            getDataStyle(item.isParms,(data)=>{
                item.postData = data
            })
        }
    }
    const returnParm = ()=>{//返回获得的参数
        return form.value
    }
    defineExpose({returnParm})
    watchEffect((e)=>{
        // if(isWatchEffect.value){
        //     return 0
        // }
        obj_value.value = props.object_;
        let from_ = {}
        for(let item of props.object_){
            from_[item.prop] === ''
            isPlatformW(item)
            getPostData(item)
            if(item.props && item.props.label){
                //有设置值
            }else{
                item.props = {
                    value:'value',
                    label:'label'
                }
            }
        }
    })
</script>

<style lang="scss" scoped>
</style>